import React, {Component} from "react";
import {
    Card, CircularProgress,
    Icon,
    IconButton, MenuItem, TextField,
    withStyles
} from "@material-ui/core";
import {connect} from "react-redux";
import CurrentUser from "../../shared/models/CurrentUser";
import {withTranslation} from "react-i18next";
import {Breadcrumb} from "../../../egret";
import {withRouter} from "react-router";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import FragmentList from "../../shared/components/FragmentList";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import AddTestResultsDialog from "./AddTestResultsDialog";
import testResultsService from "../../shared/services/testResultsService";
import cloneDeep from "lodash/cloneDeep";
import {authRoles} from "../../auth/authRoles";
import testPlanService from "../../shared/services/testPlanService";
import parentServiceMobile from "../../shared/services/parentServiceMobile";
import {helperService} from "../../shared/services/helperService";
import {currentUserService} from "../../shared/services/currentUserService";
import AddTestPlanDialog from "../testPlan/AddTestPlanDialog";
import pdfReportService from "../../shared/services/pdfReportService";
import i18n from "i18next";
import Button from "@material-ui/core/Button";
import {saveAs} from 'file-saver';

const styles = () => ({
    wrapper: {
        position: "relative",
        textAlign: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    disabledButton: {
        color: "lightgray !important"
    },
    chip: {
        margin: 2,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    rightContent: {
        margin: '0 auto',
        maxWidth: '300px'
    },
    heading: {
        fontWeight: 'bold',
        color:'#941bcc',
        fontSize: '18px'
    }
});

class TestResultsDetails extends Component {
    state = {
        shouldShowTestResultsDataDialog: false,
        testPlans: [],
        selectedTestPlan: null,
        testResult: null,
        selectedTestNotes: [],
        testResultDataToEdit: null,
        athletes: [],
        selectedAthleteId: null,
        loading: false,
        displayAddTestPlanDialog: false,
        downloadingPdfStarted : false
    };

    componentDidMount() {
        const currentUser = new CurrentUser(this.props.user);

        if(currentUser.hasRole(authRoles.athlete)) {
            const query = {};
            query['clubId'] = currentUser.club.id;

            testPlanService.getTestPlans(query).then((testPlans) => {
                this.setState({
                    ...this.state,
                    testPlans: testPlans,
                    selectedTestPlan: testPlans[0],
                    testResult: this.props.location.state.testResult,
                    currentUser: currentUser
                }, () => {
                    this.updateNotes();
                })
            });
        } else if(currentUser.hasRole(authRoles.parent)) {

            parentServiceMobile.getParentByAccessUserId(currentUser.id)
                .then(parent => {
                    let athletes = helperService.getActiveUsersMobile(parent.athletes);

                    const query = {};
                    query['clubId'] = currentUser.club.id;

                    testPlanService.getTestPlans(query).then((testPlans) => {
                        if(athletes.length > 0) {
                            testResultsService.getTestResultById(athletes[0].accessUserId).then(res => {
                                this.setState({
                                    ...this.state,
                                    testPlans: testPlans,
                                    selectedTestPlan: testPlans[0],
                                    testResult: res,
                                    currentUser: currentUser,
                                    athletes: athletes,
                                    selectedAthleteId: athletes[0].accessUserId
                                }, () => {
                                    this.updateNotes();
                                })
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                testPlans: testPlans,
                                selectedTestPlan: testPlans[0],
                                currentUser: currentUser
                            }, () => {
                                this.updateNotes();
                            })
                        }
                    });

                });

        } else {
            this.setState({
                ...this.state,
                testPlans: this.props.location.state.testPlans,
                selectedTestPlan: this.props.location.state.selectedTestPlan,
                testResult: this.props.location.state.testResult,
                currentUser: currentUser
            }, () => {
                this.updateNotes();
            })
        }
    }

    updateNotes = () => {
        let selectedTestNotes = [];
        if(this.state.testResult && this.state.testResult.testDetailsModel) {
            for(let details of this.state.testResult.testDetailsModel) {
                if(details.testPlanModel.id === this.state.selectedTestPlan.id) {
                    selectedTestNotes = details.testNotes;
                    selectedTestNotes = this.addTestNotesDataPercentage(details, details.testNotes)
                    break;
                }
            }
        }
        this.setState({
            ...this.state,
            selectedTestNotes: selectedTestNotes
        });
    };
    addTestNotesDataPercentage(details, selectedTestNotes) {
        let reference = details.testPlanModel.refValue
        let isReferenceNumber = reference !== null ?  /^\d+(\.\d+)?$/.test(reference) : null
        if(selectedTestNotes.length > 1) {
            for(let i = 0; i < selectedTestNotes.length - 1; i += 1) {
                selectedTestNotes[i].evolutionPercentage = helperService.computePercentage(selectedTestNotes[i + 1].value, selectedTestNotes[i].value)
                if ( isReferenceNumber === false || reference === "0"){
                    selectedTestNotes[i].referencePercentage = ""
                }
                else  if (reference === null) {
                    selectedTestNotes[i].missingRefValue = true
                    selectedTestNotes[i].referencePercentage = ""
                }
                else {
                    selectedTestNotes[i].referencePercentage = helperService.computePercentage(reference, selectedTestNotes[i].value)
                }
            }
            selectedTestNotes[selectedTestNotes.length - 1].evolutionPercentage = 0
            if ( isReferenceNumber === false || reference === "0") {
                selectedTestNotes[selectedTestNotes.length - 1].referencePercentage = ""
            }
            else  if (reference === null) {
                selectedTestNotes[selectedTestNotes.length - 1].missingRefValue = true
                selectedTestNotes[selectedTestNotes.length - 1].referencePercentage = ""
            }
            else {
                selectedTestNotes[selectedTestNotes.length - 1].referencePercentage = helperService.computePercentage(reference, selectedTestNotes[selectedTestNotes.length - 1].value)
            }
        }
        else if (selectedTestNotes.length === 1) {
            if ( isReferenceNumber === false || reference === "0"){
                selectedTestNotes[0].referencePercentage = ""
            }
            else  if (reference === null) {
                selectedTestNotes[0].missingRefValue = true
                selectedTestNotes[0].referencePercentage = ""
            }
            else {
                selectedTestNotes[0].referencePercentage = helperService.computePercentage(reference, selectedTestNotes[0].value)
            }
            selectedTestNotes[0].evolutionPercentage = 0
        }
        return selectedTestNotes
    }
    goBack() {
        if(this.state.currentUser.hasRole(authRoles.parent) || this.state.currentUser.hasRole(authRoles.athlete)) {
            this.props.history.push('/dashboard/summary');
        } else {
            this.props.history.push('/sportFiles/tests');
        }
    }

    openAddTestResultsDialog = () => {
        this.setState({
            shouldShowTestResultsDataDialog: true,
        });
    };

    handleAddTestResultsDialogClose = () => {
        this.setState({
            shouldShowTestResultsDataDialog: false,
            testResultDataToEdit: null
        });
    };

    addData = (value) => {
        this.setState({
            ...this.state,
            loading: true
        })
        if(this.state.testResult.testDetailsModel === null) {
            let testNotes = [];
            testNotes.push({'value': value, 'lastModifiedByDate': new Date(), 'lastModifiedByFullName': this.state.currentUser.firstName + " " + this.state.currentUser.lastName, 'evolutionPercentage': 0, 'referencePercentage': "", 'missingRefValue': true});

            let testDetails = [];
            testDetails.push({'testPlanModel': this.state.selectedTestPlan, 'testNotes': testNotes});
            this.state.testResult.testDetailsModel = testDetails;
        } else {
            let testModelExist = false;
            let evolutionPercentage;
            let referencePercentage;
            let reference;
            for(let model of this.state.testResult.testDetailsModel) {
                if(model.testPlanModel.id === this.state.selectedTestPlan.id) {
                    if (model.testNotes !== null && model.testNotes.length > 0) {
                        let lastValue = model.testNotes[0].value
                        reference = model.testPlanModel.refValue
                        let isReferenceNumber = reference !== null ? /^\d+(\.\d+)?$/.test(reference) : null
                        evolutionPercentage = helperService.computePercentage(lastValue, value)
                        let referencePercentage;
                        if (isReferenceNumber === false || reference === "0")
                            referencePercentage = ""
                        else if (reference === null) {
                            referencePercentage = ""
                        } else referencePercentage = helperService.computePercentage(reference, value)
                    }
                    else  {
                        referencePercentage = ""
                        evolutionPercentage = 0
                        reference = null
                    }
                    model.testNotes.unshift({'value': value, 'lastModifiedByDate': new Date(), 'lastModifiedByFullName': this.state.currentUser.firstName + " " + this.state.currentUser.lastName, 'evolutionPercentage': evolutionPercentage, 'referencePercentage': referencePercentage, 'missingRefValue': reference === null});
                    testModelExist = true;
                    break;
                }
            }

            if(!testModelExist) {
                let testNotes = [];
                testNotes.push({'value': value, 'lastModifiedByDate': new Date(), 'lastModifiedByFullName': this.state.currentUser.firstName + " " + this.state.currentUser.lastName, 'evolutionPercentage': 0, 'referencePercentage': "",'missingRefValue': true});

                this.state.testResult.testDetailsModel.push({'testPlanModel': this.state.selectedTestPlan, 'testNotes': testNotes});
            }
        }

        testResultsService.addTestResult(this.state.testResult, this.state.currentUser.club.id).then(() => {
            const query = {};
            query["testResultId"] = this.state.testResult.id;

            testResultsService.getTestResults(query).then((res) => {
                console.log("res: ", res);
                this.setState({
                    ...this.state,
                    testResult: res[0],
                    loading: false,
                    shouldShowTestResultsDataDialog: false
                }, () => {
                    this.updateNotes();
                });
            });

        });

    };

    editData = (value) => {
        this.setState({
            ...this.state,
            loading: true
        });
        let reference;
        let isReferenceNumber;
        for(let model of this.state.testResult.testDetailsModel) {
            if (model.testPlanModel.id === this.state.selectedTestPlan.id) {
                reference = model.testPlanModel.refValue
                isReferenceNumber = reference !== null ?  /^\d+(\.\d+)?$/.test(reference) : null
            }
        }
        if (this.state.selectedTestNotes.length === 1) {
            if(this.state.selectedTestNotes[0].lastModifiedByDate === this.state.testResultDataToEdit.lastModifiedByDate) {
                this.state.selectedTestNotes[0].value = value;
                this.state.selectedTestNotes[0].lastModifiedByDate = new Date()
                this.state.selectedTestNotes[0].evolutionPercentage = 0
                if ( isReferenceNumber === false || reference === "0")
                    this.state.selectedTestNotes[0].referencePercentage = ""
                else  if (reference === null) {
                    this.state.selectedTestNotes[0].referencePercentage = ""
                    this.state.selectedTestNotes[0].missingRefValue = true
                }
                else this.state.selectedTestNotes[0].referencePercentage = helperService.computePercentage(reference, value)
            }
        }
        else
            for (let note of this.state.selectedTestNotes) {
                if (note.lastModifiedByDate === this.state.testResultDataToEdit.lastModifiedByDate) {
                    note.value = value;
                    note.lastModifiedByDate = new Date()
                    note.evolutionPercentage = helperService.computePercentage(this.state.selectedTestNotes[0].value, value)
                    if (isReferenceNumber === false || reference === "0")
                        note.referencePercentage = ""
                    else if (reference === null) {
                        note.referencePercentage = ""
                        note.missingRefValue = true
                    } else note.referencePercentage = helperService.computePercentage(reference, value)
                }
            }
        for(let model of this.state.testResult.testDetailsModel) {
            if(model.testPlanModel.id === this.state.selectedTestPlan.id) {
                model.testNotes = this.state.selectedTestNotes;
                break;
            }
        }

        testResultsService.addTestResult(this.state.testResult, this.state.currentUser.club.id).then(() => {
            this.setState({
                shouldShowTestResultsDataDialog: false,
                testResultDataToEdit: null,
                loading: false
            });
        });
    };
    handleDialogClose = () => {
        this.setState({
            ...this.state,
            displayAddTestPlanDialog: false
        })
    };

    handleSelectChange = event => {
        event.persist();
        if(event.target.name === 'testName') {
            for(let plan of this.state.testPlans) {
                if(event.target.value === plan.id) {
                    this.setState({
                        ...this.state,
                        selectedTestPlan: plan
                    }, () => {
                        this.updateNotes();
                    });
                    break;
                }
            }
        } else {
            testResultsService.getTestResultById(event.target.value.toString()).then(res => {
                this.setState({
                    ...this.state,
                    testResult: res,
                    selectedAthleteId: event.target.value
                }, () => {
                    this.updateNotes();
                })
            });
        }
    };

    openEditTestResults = (dataToEditId) => {
        for(let note of this.state.selectedTestNotes) {
            if(note.id === dataToEditId) {
                this.setState({
                    shouldShowTestResultsDataDialog: true,
                    testResultDataToEdit: note
                });
                break;
            }
        }
    };

    handleConfirmationRemoveTestResults = (selectedTestResultDataToRemove) => {
        this.setState({
            ...this.state,
            loading: true
        });
        let remainingTestNotes = cloneDeep(this.state.selectedTestNotes);
        selectedTestResultDataToRemove.forEach(id => {
            remainingTestNotes = remainingTestNotes.filter(test => test.id !== id);
        });
        for(let model of this.state.testResult.testDetailsModel) {
            if(model.testPlanModel.id === this.state.selectedTestPlan.id) {
                model.testNotes = remainingTestNotes;
                model.testNotes = this.addTestNotesDataPercentage(model, model.testNotes);
                break;
            }
        }

        testResultsService.addTestResult(this.state.testResult, this.state.currentUser.club.id).then(() => {
            this.setState({
                ...this.state,
                selectedTestNotes: remainingTestNotes,
                loading: false
            })
        });

    };
    showTestPlan = () => {
        this.setState( {...this.state, displayAddTestPlanDialog: true})
    }
    hasTestResults(testPlan, testResults) {
        //console.log("testResults: ", testResults)
        if(testPlan && testResults && testResults.testDetailsModel) {
            for (let model of testResults.testDetailsModel) {
                if (model.testPlanModel.id === testPlan.id) {
                    if (model.testNotes.length > 0)
                        return true
                }
            }
        }
        return false
    }
    /**
     * Download athlete test results in pdf file
     */
    downloadPdfReport(testPlan, testResult) {
        const query = {...this.state.query};
        Object.keys(query).forEach((key) => (key === "pagination" || query[key] === '' || query[key] === 'All') && delete query[key]);

        this.setState({
            ...this.state,
            downloadingPdfStarted: true
        }, () => {
            pdfReportService.getUserLanguage().then((language) => {
                pdfReportService.downloadTestResultsAthlete(testResult.athleteId, testPlan.id).then((res) => {
                    const userSetLng = i18n.getFixedT(language.toLowerCase());
                    saveAs(res, userSetLng('report.athleteTestResultsTitle', { testName: testPlan.longName, athleteName: testResult.athleteLastName + " " + testResult.athleteFirstName}) + " " + helperService.dateUniversalFormat(new Date()) + ".pdf");
                    this.setState({
                        ...this.state,
                        downloadingPdfStarted: false
                    });
                }).catch(() => {
                    this.setState({
                        ...this.state,
                        downloadingPdfStarted: false
                    });
                });
            }).catch(() => {
                this.setState({
                    ...this.state,
                    downloadingPdfStarted: false
                });
            });
        });
    }

    getPropsRefValue = () => {
        return this.props.location && this.props.location.state && this.props.location.state.selectedTestPlan ? this.props.location.state.selectedTestPlan.refValue : ""
    }
    render() {

        const {t, classes} = this.props;
        const {loading} = this.state;

        const columns = ['form.value', 'form.lastModifiedDate', 'form.lastModifiedBy', 'form.evolutionTests', this.state.selectedTestPlan ?  t('form.referenceTests') + "\n          (= " + this.state.selectedTestPlan.refValue  + ")" : t('form.referenceTests') + "\n          (= " + this.getPropsRefValue() + ")"];
        const topFields = ['value', 'lastModifiedByDate', 'lastModifiedByFullName', 'evolutionPercentage', 'referencePercentage'];
        let hasResult = this.state.selectedTestPlan && this.state.testResult ? this.hasTestResults(this.state.selectedTestPlan, this.state.testResult) : this.hasTestResults(this.props.location && this.props.location.state ? this.props.location.state.selectedTestPlan : null, this.props.location && this.props.location.state ? this.props.location.state.testResult : null);

        return (
            <div className="m-sm-30">
                <div className="mb-sm-30">
                    <Breadcrumb
                        routeSegments={[
                            {name: t('menu.test.result.title'), path: "/sportfiles/tests"},
                            {
                                name: (this.props.location.state.testResult.athleteFirstName || '') + ' ' + (this.props.location.state.testResult.athleteLastName || ''),
                                path: `/athlete/${this.props.location.state.testResult.athleteId}`
                            }]}
                    />
                </div>
                <Card elevation={3}>
                    <div className="invoice-viewer">
                        <div className="viewer_actions flex flex-space-between flex-middle bg-primary fixed-content" id="header">
                            <IconButton onClick={() => this.goBack()}>
                                <Icon className="text-white">arrow_back</Icon>
                            </IconButton>
                            <h4 className="my-16 text-white">{t('menu.test.result.title') + ' ' + (this.props.location.state.isParent !== true ? (this.props.location.state.testResult.athleteFirstName + ' ' + this.props.location.state.testResult.athleteLastName) : (''))}</h4>
                            <div className="flex flex-space-between flex-middle">
                            </div>
                        </div>
                        <br/>
                    </div>

                    <div className="flex flex-row">

                        <div style={{ paddingLeft: "15px"}}>
                            <div className="flex flex-space-between mb-16">
                                {t('table.head.user.testName')}
                            </div>
                            <div className="flex flex-middle mb-16">
                                <FormControl className="formControl">
                                    <TextField
                                        variant="outlined"
                                        name="testName"
                                        placeholder = {t('form.testName')}
                                        value = {this.state.selectedTestPlan ? this.state.selectedTestPlan.id : ''}
                                        className="select"
                                        select
                                        onChange={this.handleSelectChange}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: ''
                                            },
                                            style: {
                                                marginRight: '12px',
                                                height: '35px'
                                            }
                                        }}
                                    >
                                        {this.state.testPlans.map((testPlan, i) => (
                                            <MenuItem value={testPlan.id} key={i}>
                                                {testPlan.shortName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </div>
                        </div>

                        {currentUserService.isParent(this.state.currentUser) && (
                            <div style={{width: "20%", paddingLeft: "15px"}}>
                                <div className="flex flex-middle mb-16">
                                    {t('table.head.user.athlete')}
                                </div>
                                <div className="flex flex-middle mb-16">
                                    <FormControl className="formControl">
                                        <TextField
                                            variant="outlined"
                                            name="athleteId"
                                            placeholder = {t('form.id')}
                                            value = {this.state.selectedAthleteId !== null ? this.state.selectedAthleteId : ''}
                                            className="select"
                                            select
                                            onChange={this.handleSelectChange}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: ''
                                                },
                                                style: {
                                                    marginRight: '12px',
                                                    height: '35px'
                                                }
                                            }}
                                        >
                                            {this.state.athletes.map((athlete, i) => (
                                                <MenuItem value={athlete.accessUserId} key={i}>
                                                    {(athlete.firstName || '') + ' ' + (athlete.lastName || '')}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                        )}

                    </div>

                    <div style={{paddingLeft: "15px"}}>
                        {this.state.selectedTestPlan ? this.state.selectedTestPlan.description : ''}
                    </div>

                    <div className="py-20">
                        <Accordion expanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div style={{flexGrow: 1}}><Typography className={classes.heading}>
                                    {this.state.selectedTestPlan ? t("menu.test.result.description") + " " + this.state.selectedTestPlan.uom + ":" : t("menu.test.result.title")}
                                </Typography> </div>
                                {hasResult && (<span className={classes.wrapper}>
                                 <Button
                                     className="mt-4"
                                     variant="contained"
                                     color="secondary"
                                     title={t('actions.pdf')}
                                     disabled={this.state.downloadingPdfStarted}
                                     onClick={() => this.downloadPdfReport(this.state.selectedTestPlan, this.state.testResult)}
                                 >
                                     <span className="ml-4"
                                           style={{marginRight: '5px'}}>{t('actions.pdf')}</span>
                                     <Icon>download</Icon>
                                     {this.state.downloadingPdfStarted && (
                                         <CircularProgress
                                             size={24}
                                             className={classes.buttonProgress}
                                         />
                                     )}
                                 </Button>
                                </span>)}
                            </AccordionSummary>
                            <AccordionDetails style={{display: "block"}}>
                                <FragmentList
                                    list={this.state.selectedTestNotes}
                                    columns={columns}
                                    topFields={topFields}
                                    bottomFields={[]}
                                    showCheckbox={this.state.currentUser && !this.state.currentUser.hasRole(authRoles.athlete) && !this.state.currentUser.hasRole(authRoles.parent)}
                                    showActions={this.state.currentUser && !this.state.currentUser.hasRole(authRoles.athlete) && !this.state.currentUser.hasRole(authRoles.parent)}
                                    showMoreDetails = {false}
                                    withoutConfirmation={true}
                                    isRemoveActive={true}
                                    handleViewClick={this.showTestPlan}
                                    emptyListMessage={t("menu.test.result.details.noTestDetails")}
                                    addButtonLabel={t('menu.test.result.details.add')}
                                    removeButtonLabel={t('menu.test.result.details.remove')}
                                    removeConformationText={t('menu.test.result.details.confirmation.remove')}
                                    openAddDialog={this.openAddTestResultsDialog}
                                    handleConfirmationRemoveResponse={this.handleConfirmationRemoveTestResults}
                                    showActionColumn = {this.state.currentUser && !this.state.currentUser.hasRole(authRoles.athlete) && !this.state.currentUser.hasRole(authRoles.parent)}
                                    secondAction = {'edit'}
                                    actionColumnName = {t('form.actions.modify')}
                                    tooltipSecondAction = {t('menu.test.result.details.edit')}
                                    handleSecondActionClick = {this.openEditTestResults}
                                    secondActionStyle = {{ color: '#941bcc' }}
                                />
                            </AccordionDetails>
                            { loading &&  (<div className={classes.wrapper}><CircularProgress /> </div>)}
                        </Accordion>
                    </div>
                </Card>
                {this.state.shouldShowTestResultsDataDialog && (
                    <AddTestResultsDialog
                        handleClose={this.handleAddTestResultsDialogClose}
                        open={this.state.shouldShowTestResultsDataDialog}
                        buttonLabel = {!this.state.testResultDataToEdit ? t('actions.add') : t('actions.edit')}
                        addValueData={!this.state.testResultDataToEdit ? this.addData : this.editData}
                        testResultDataToEdit = {this.state.testResultDataToEdit}
                        loading = {this.state.loading}
                    />
                )}
                {this.state.displayAddTestPlanDialog && (
                    <AddTestPlanDialog
                        open = {this.state.displayAddTestPlanDialog}
                        handleClose = {() => this.handleDialogClose()}
                        handleUpdate = {(testPlan) => {
                            this.setState({
                                ...this.state,
                                displayAddTestPlanDialog: false
                            }, () => {
                                testPlanService.updateTestPlan(testPlan, this.state.currentUser.club.id).then(() => {this.updateNotes()})});
                        }}
                        editMode = {true}
                        testPlan = {this.state.selectedTestPlan}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    athleteObservation: state.athleteObservation,
    user: state.user
});

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            {  }
        )(withTranslation()(TestResultsDetails))
    )
);
